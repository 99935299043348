import React, { useRef, useContext, useState, useEffect } from "react"
import { useNavigate, useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown'
import { useSmoothScroll, useScrollWatch } from 'react-smooth-scroll-hook'
import { siteContext } from '../App';
import loadingIcon from '../../images/loading.gif';
import closeIcon from '../../images/icon-close.svg';
import arrowIcon from '../../images/icon-arrow.svg';
import GalleryImage from './gallery-image.js'

function GalleryWork (props) {
    const navigate = useNavigate()
    const location = useLocation()
    const wrapperRef = useRef(null);

    const ref = props.refProp;
    const { scrollTo } = useSmoothScroll({
      ref,
      speed: 100,
      direction: 'y',
      behavior: "smooth",
      offset: -10
    })

    const {
        pages, setPages,
        galleryItem, setGalleryItem,
        galleryActive, setGalleryActive,
        galleryIndex, setGalleryIndex
    } = useContext(siteContext);

    const handleClickOutside = (event: Event) => {
        if (
            wrapperRef.current &&
            !wrapperRef.current.contains(event.target)
        ) {
            navigate('/gallery')
            scrollTo('#gallery')
            setGalleryActive(false)
        }
    }

    useEffect( () => {
        document.addEventListener('click', handleClickOutside, true)
    })

    // useEffect( () => {
        // console.log(galleryIndex)
    // }, [galleryIndex])

    function getCurrentRouteURL (pathnameParam) {
        return pathnameParam.split(':')[1];
    }

    function galleryIndexReturn (item, index) {
        const galleryItems = pages.gallery.works
        let currentRouteURL = getCurrentRouteURL(location.pathname)
        var newIndex = 0
        galleryItems.forEach((item, index) => {
            if (item.projectid === currentRouteURL) {
                newIndex = index
            }
        })

        return newIndex
    }

    function arrowClickHandler (direction) {
        const galleryItems = pages.gallery.works
        let currentRouteURL = getCurrentRouteURL(location.pathname)
        let workIndex = galleryIndexReturn()

        if (direction === 'right') { // Right
            if (workIndex === galleryItems.length-1) {
                navigate('/gallery/:' + galleryItems[0].projectid)
                setGalleryItem(galleryItems[0])
                setGalleryIndex(0)
            } else {
                workIndex++
                navigate('/gallery/:' + galleryItems[workIndex].projectid)
                setGalleryItem(galleryItems[workIndex])
                setGalleryIndex(workIndex)
            }
        } else { // Left
            if (workIndex === 0) {
                workIndex = galleryItems.length-1
                navigate('/gallery/:' + galleryItems[workIndex].projectid)
                setGalleryItem(galleryItems[workIndex])
                setGalleryIndex(workIndex)
            } else {
                workIndex--
                navigate('/gallery/:' + galleryItems[workIndex].projectid)
                setGalleryItem(galleryItems[workIndex])
                setGalleryIndex(workIndex)
            }
        }

    }

    const descriptionsReturn = galleryItem.description.map( (descriptionItem, descriptionIndex) => {
        return (
            <div className="gallery-description-item" key={descriptionIndex}>
                <ReactMarkdown linkTarget="_blank">{descriptionItem}</ReactMarkdown>
            </div>
        )
    })

    const imagesReturn = galleryItem.images.map((image, index) => {
        return (
            <GalleryImage key={index} imageProp={image}/>
        )
    })

    const videosReturn = galleryItem.videos.map((videoItem, index) => {
        return (
            <div key={index} className="gallery-video">
            {videoItem.title !== null ? <h5>{videoItem.title}</h5> : null}
                <iframe width="560" height="316"
                src={videoItem.src}>
                </iframe>
            </div>
        )
    })

    return (
        <div className={`${galleryActive ? 'gallery-work-visible' : null} gallery-work lightboxed`}>
            <div className="gallery-work-inner" ref={wrapperRef}>
                <div className="gallery-arrow gallery-left" onClick={() => arrowClickHandler('left')}>
                    <img src={arrowIcon} />
                </div>
                <div className="gallery-work-content">
                    <h1>{galleryItem.name}</h1>
                    <hr />
                    {descriptionsReturn}
                    {imagesReturn}
                    {videosReturn}
                </div>
                <div className="gallery-arrow gallery-right" onClick={() => arrowClickHandler('right')}>
                    <img src={arrowIcon} />
                </div>
            </div>
            <div className="close-wrapper">
                <img src={closeIcon} />
            </div>
        </div>
    )
}

export default GalleryWork;