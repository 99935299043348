import React, { useRef, useContext, useState, useEffect } from 'react'
import { Link, useLocation } from "react-router-dom";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import { siteContext } from '../App'

import logo from '../../logo.svg'
import testImg from '../../images/works/intro/rta_website.png'

import GalleryWork from './gallery-work.js'
import GalleryThumb from './gallery-thumb.js'

function Gallery (props) {
    const location = useLocation()

    const {
        pages, setPages,
        galleryItem, setGalleryItem,
        galleryActive, setGalleryActive,
        galleryIndex, setGalleryIndex
    } = useContext(siteContext);

    function galleryClickHandler (galleryWorkParam, indexParam) {
        setGalleryActive(true)
        setGalleryItem(galleryWorkParam)
        setGalleryIndex(indexParam)
    }

    const galleryItems = pages.gallery.works

    const galleryReturn = galleryItems.map( (galleryItem, index) => {
        if (!galleryItem.hidden) {
            return (
                <div className={`gallery-thumbnails-thumb`} key={index}>
                    <Link to={'/gallery/:' + galleryItem.projectid} onClick={() => {galleryClickHandler(galleryItem, index)}}>
                        <GalleryThumb galleryItem={galleryItem} />
                    </Link>
                </div>
            )
        }
    })

    const brandsReturn = pages.brands.map( (galleryItem, index) => {
        return (
            <div key={index}>
                <img src={galleryItem} />
            </div>
        )
    })

    return (
        <div className={`${props.activePage === 'gallery' ? 'active' : ''} gallery page`} id="gallery">

            <div className="gallery-thumbnails-wrapper">
                <h2>Gallery</h2>
                <div className="gallery-thumbnails">
                    <ResponsiveMasonry
                        columnsCountBreakPoints={{350: 1, 750: 2, 900: 4}}
                    >
                        <Masonry>
                            {galleryReturn}
                        </Masonry>
                    </ResponsiveMasonry>
                </div>

                <div className="gallery-brands">
                    <h2>Brands I've worked with:</h2>
                    <div className="gallery-brands-images">
                        {brandsReturn}
                    </div>
                </div>
            </div>

            <div className="gallery-page-inner page-inner">
                { galleryActive ? <GalleryWork galleryItem={galleryItem} galleryIndex={0} refProp={props.refProp} /> : null }
            </div>

        </div>
    )
}

export default Gallery;
