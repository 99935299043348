import React, { useRef, createContext, useState, useEffect, useLayoutEffect } from "react"
import { useNavigate } from "react-router-dom";
import { useSmoothScroll, useScrollWatch } from 'react-smooth-scroll-hook'

import logo from '../images/site-logo-v2.svg'
import '../css/main.css'

import Home from './components/home'
import About from './components/about'
import Gallery from './components/gallery'
import Contact from './components/contact'
import Menu from './components/menu'

import pagesImport from './data/pages'

// import scrollingHandler from './modules/scrollingHandler'

export const siteContext = createContext()

function App(props) {
    const [test, setTest] = useState('Test string')
    const [pages, setPages] = useState(pagesImport)
    const [galleryActive, setGalleryActive] = useState(false)
    const [galleryItem, setGalleryItem] = useState(pages.gallery.works[0])
    const [galleryIndex, setGalleryIndex] = useState(0)
    const [navStick, setNavStick] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false)
    const [homePage, setHomePage] = useState(null)
    const [aboutPage, setAboutPage] = useState(null)
    const [galleryPage, setGalleryPage] = useState(null)
    const [contactPage, setContactPage] = useState(null)
    const [navigation, setNavigation] = useState(null)
    const [activePage, setActivePage] = useState('home')
    const [lastScrollTop, setLastScrollTop] = useState(0)
    const [submitted, setSubmitted] = useState(false);
    const [mouseX, setMouseX] = useState(0);
    const [mouseY, setMouseY] = useState(0);

    const navigate = useNavigate()
    const ref = useRef(document.body);

    useEffect(() => {
        window.addEventListener('scroll', scrolling)
    })

    useEffect(() => {
        setHomePage(document.getElementById('home'))
        // setAboutPage(document.getElementById('about'))
        setGalleryPage(document.getElementById('gallery'))
        setContactPage(document.getElementById('contact'))
        setNavigation(document.getElementById('navigation'))
    }, [homePage])

    const { scrollTo } = useSmoothScroll({
      ref,
      speed: 100,
      direction: 'y',
      behavior: "smooth",
      offset: -10
    })

    function scrolling (e) {
        const pagesScroll = e.target.scrollTop;

        if (pagesScroll >= navigation.offsetHeight) {
            setNavStick(true)
        } else if (pagesScroll <= navigation.offsetHeight) {
            setNavStick(false)
        }

        if (pagesScroll >= 0 &&
            pagesScroll < (galleryPage.offsetTop/2)) {
           setActivePage('home')
           navigate('/home', { replace: true })
        }
        // else if (pagesScroll > (homePage.offsetHeight/2) &&
        //     pagesScroll < (aboutPage.offsetTop+(aboutPage.offsetHeight/2))) {
        //    setActivePage('about')
        //    navigate('/about', { replace: true })
        // }
        else if (pagesScroll > (homePage.offsetTop+(homePage.offsetHeight/2)) &&
            pagesScroll < (galleryPage.offsetTop+(galleryPage.offsetHeight/2))) {
            setActivePage('gallery')
            navigate('/gallery', { replace: true })
        } else if (pagesScroll > (galleryPage.offsetTop+(galleryPage.offsetHeight/2)) &&
            pagesScroll < (contactPage.offsetTop+(contactPage.offsetHeight/2))) {
            setActivePage('contact')
            navigate('/contact', { replace: true })
        }

        var lastScrollTopSet = pagesScroll <= 0 ? 0 : pagesScroll;
        setLastScrollTop(lastScrollTopSet)
    }

    return (
        <siteContext.Provider value={{
            pages, setPages,
            galleryItem, setGalleryItem,
            galleryActive, setGalleryActive,
            menuOpen, setMenuOpen,
            galleryIndex, setGalleryIndex,
            activePage, setActivePage,
            submitted, setSubmitted,
            mouseX, setMouseX,
            mouseY, setMouseY,
            homePage, setHomePage
        }}>

        <div
        id="mhc"
        className="mhc"
        ref={ref}
        style={{overflowY: 'scroll', maxHeight: '100vh'}}
        onScroll={scrolling}>

            <div id="navigation" className={navStick ? 'navigation sticky' : 'navigation'} >
                <div className="navigation-inner">
                    <img src={logo} className="app-logo" alt="logo" onClick={() => scrollTo('#home')} />

                    <nav className="menu desktop">
                      <Menu refProp={ref}/>
                    </nav>

                    <div className={menuOpen ? "hamburger-nav menu-open" : "hamburger-nav"} onClick={() => setMenuOpen(!menuOpen)}>
                        <div className="hamburger-icon" >
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>

                        <nav className="menu">
                          <Menu refProp={ref}/>
                        </nav>
                    </div>

                </div>
            </div>

            <Home activePage={activePage} />
            {/*<About activePage={activePage} />*/}
            <Gallery activePage={activePage} refProp={ref} />
            <Contact activePage={activePage} />

        </div>

        </siteContext.Provider>
    )
}

export default App
