import React, { useRef, useContext, useState, useEffect } from "react"
import { siteContext } from '../App';
import loadingIcon from '../../images/loading.gif';

function GalleryThumb (props) {
    const {
        pages, setPages
    } = useContext(siteContext);

    const [imageLoaded, setImageLoaded] = useState(false)

    return (
        <div className='gallery-work-image-wrapper gallery-work-thumb'>
            <div className={imageLoaded ? 'hidden' : ''}>
                <h5>Loading</h5>
                <img src={loadingIcon} className='gallery-work-image gallery-work-thumb-img' alt="" />
            </div>

            <img src={props.galleryItem.thumb} className={!imageLoaded ? 'hidden' : 'gallery-work-thumb-img'} alt="" onLoad={() => {
                setImageLoaded(true)
            }} />
        </div>
    )
}

export default GalleryThumb;
