import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './js/App'
import reportWebVitals from './reportWebVitals'

import {
  createBrowserRouter,
  RouterProvider,
  Route
} from "react-router-dom";

// Pages
import pagesImport from './js/data/pages'

// let pagesArray = [{path: "/",element: <App />}]

let pagesArray = [
  {
    path: "/",
    element: <App routeParam='element-zero' />,
    errorElement: <App routeParam='error'/>,
    children: [
      {path: "/home", element: <App routeParam='home' />},
      // {path: "/about", element: <App routeParam='about' />},
      {path: "/gallery", element: <App routeParam='gallery' />},
      {path: "/gallery/:id", element: <App routeParam='gallery-modal' />},
      {path: "/contact", element: <App routeParam='contact' />}
    ]
  }
]


// Object.keys(pagesImport).forEach((item, index) => {
//     pagesArray.push({path: item, element: <App page={item} />})
// })

// Gallery works
// pagesImport.gallery.works.forEach((item, index) => {
//     pagesArray.push({path: item.name, element: <App page={item.name} />})
// })

const router = createBrowserRouter(pagesArray);

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
    <RouterProvider router={router}/>
  // </React.StrictMode>
)

// <App />

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
