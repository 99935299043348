import React, { useRef, createContext, useState, useEffect } from "react"
import { siteContext } from '../App'
import ContactForm from './contact-form.js'

function Contact (props) {
    return (
        <div className={`${props.activePage === 'contact' ? 'active' : ''} contact page`} id="contact">
            <div className="page-inner">
                <p></p>
                <div className="contact-form">
                    <ContactForm />
                </div>
            </div>
        </div>
    )
}

export default Contact;