import React, { useContext, useState } from "react";
import { siteContext } from '../App'

const FORM_ENDPOINT = "https://public.herotofu.com/v1/076cad20-a34f-11ed-a31e-753411848f80";

function ContactForm () {
    const [nameActive, setNameActive] = useState(false)
    const [emailActive, setEmailActive] = useState(false)
    const [msgActive, setMsgActive] = useState(false)

    const {
        pages, setPages,
        submitted, setSubmitted
    } = useContext(siteContext);

    const handleSubmit = () => {
      setTimeout(() => {
        setSubmitted(true);
      }, 100);
    }

    if (submitted) {
      return (
        <>
          <h2>Thank you!</h2>
          <h5>I've received your message. I'll be in touch with you soon.</h5>
        </>
      );
    }

    return (
        <div className="contact-form-inner">
          <h2>Let's Connect</h2>
            {submitted}

            <form
              action={FORM_ENDPOINT}
              onSubmit={handleSubmit}
              method="POST"
              target="_blank"
            >
              <div className="input-label-wrapper">
                <label htmlFor="name">Name</label><br />
                <input type="text" name="name" required />
              </div>
              <div className="input-label-wrapper">
                <label htmlFor="email">Email</label><br />
                <input type="email" name="email" required />
              </div>
              <div className="input-label-wrapper">
                <label htmlFor="message">Message</label><br />
                <textarea name="message" required />
              </div>
              <div className="submit-wrapper button-treatment">
                <button type="submit" className="submit-btn"> Send a message </button>
              </div>
            </form>
        </div>
    )

}

export default ContactForm;