import React, { useRef, createContext, useContext, useState, useEffect } from "react"
import { siteContext } from '../App';

function Home (props) {

    const {
        pages, setPages,
        homePage, setHomePage
    } = useContext(siteContext);

    return (
        <div
            id="home"
            className={`${props.activePage === 'home' ? 'active' : ''} home page`}
        >
            <div className="page-inner">

            <h2>Who am I?</h2>

            <div className="home-text">
                <p>A Multimedia Developer specialising in Front End Development.</p>
            </div>

            <div className="home-text">
                <h3>My qualifications:</h3>
                <ul>
                    <li>Bachelor of Interactive Media</li>
                    <li>Advanced Diploma of Printing & Graphic Art</li>
                    <li>Advanced Diploma of Screen Media</li>
                </ul>
            </div>

            </div>
        </div>
    )
}

export default Home;