import React, { useContext, useEffect } from "react"
import { ReactDOM } from 'react-dom/client';
import { useNavigate, useLocation } from 'react-router-dom';
import { siteContext } from '../App'
import { useSmoothScroll, useScrollWatch } from 'react-smooth-scroll-hook'
import { Link } from "react-router-dom";

function Menu (props) {
    const location = useLocation()
    const navigate = useNavigate()

    const ref = props.refProp;
    const { scrollTo } = useSmoothScroll({
      ref,
      speed: 100,
      direction: 'y',
      behavior: "smooth",
      offset: -10
    })

    const {
        pages, setPages,
        activePage, setActivePage,
        galleryItem, setGalleryItem,
        galleryActive, setGalleryActive,
        galleryIndex, setGalleryIndex
    } = useContext(siteContext);

    // const { scrollTop, curIndex, curItem } = useScrollWatch({
    //   ref,
    //   list: [
    //     {
    //       href: '#home'
    //     },
    //     {
    //       href: '#about',
    //     },
    //     {
    //       href: '#gallery'
    //     },
    //     {
    //       href: '#contact'
    //     }
    //   ]
    // })

    // function scrollContainer () {
    //     return (
    //         <div style={{position: 'fixed', top: 50, right: '20px', background: 'white'}}>
    //             <h2>Scroll Container Mode</h2>
    //             <p>
    //                 <strong>scrollTop:</strong> {scrollTop}
    //             </p>
    //             <p>
    //                 <strong>curIndex:</strong> {curIndex}
    //             </p>
    //             <p>
    //                 <strong>curHref:</strong> {curItem?.href}
    //             </p>
    //         </div>
    //     )
    // }

    const pageKeys = Object.keys(pages)
    const navMenuItemsReturn = pageKeys.map((item, index) => {
        if ((item !== 'hiddenWorks') && (item !== 'brands')) {
            return (
                <div
                    key={index}
                    onClick={() => scrollTo('#' + item)}
                    className={`${activePage === item ? 'menu-inner-item active button-treatment': 'menu-inner-item button-treatment'} ${item}`}
                >
                    <Link
                        to={'/' + item}
                        key={index}
                        className=""
                    >
                        {item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()}
                    </Link>
                </div>
            )
        }
    })

    useEffect(() => {
        // console.log(Object.keys(pages))
        // Object.keys(pages).forEach((item, index) => {
        //     console.log(item, item !== 'hiddenWorks' && item !== 'brands')
        // })
    }, [])

    function getCurrentRouteURL (pathnameParam) {
        return pathnameParam.split(':')[1];
    }

    function pageNamer () {
        let currentPage
        Object.keys(pages).forEach((item, index) => {
            if (location.pathname.includes(item)) {
                currentPage = item
            }
        })
        return currentPage
    }

    function galleryIndexReturn (item, index) {
        const galleryItems = pages.gallery.works
        let currentRouteURL = getCurrentRouteURL(location.pathname)
        var newIndex = 0
        galleryItems.forEach((item, index) => {
            if (item.projectid === currentRouteURL) {
                newIndex = index
            }
        })

        return newIndex
    }

    function pathfinder () {
        const pageNames = Object.keys(pages)
        let pathname = location.pathname

        if (typeof pageNamer() !== 'undefined') {
            if (pathname.includes('gallery/:')) {
                const galleryItems = pages.gallery.works
                let workIndex = galleryIndexReturn()
                setGalleryActive(true)
                setGalleryItem(galleryItems[workIndex])
                setGalleryIndex(workIndex)
            } else {
                scrollTo('#' + pageNamer())
                navigate('/' + pageNamer())
            }
        } else {
            scrollTo('#home')
            navigate('/home')
        }
    }

    // Scroll to page on load/refresh
    useEffect( () => {
        window.onpopstate = e => {
           pathfinder()
        }
        pathfinder()
    }, [])

    return (
        <div className="menu-inner">
            {navMenuItemsReturn}
        </div>
    )
}

export default Menu;
