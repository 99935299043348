const pages = {
    home: {},
    // about: {},
    gallery: {
        works: [
            {
                name: 'Remba Imaging',
                hidden: false,
                projectid: 'remba-imaging',
                description: ["### Poster", "Remba converts all of your photos, slides and negatives into high quality digital images.", "I put a strong emphasis on typography in the design.", "**Design of poster utilising Adobe Photoshop**"],
                thumb: 'https://moehammoud.com/images/works/intro/remba_imaging_a2_poster.png',
                images: [
                    {src: 'https://moehammoud.com/images/works/full/remba_imaging_a2_poster.jpg', alt: 'image 1.1'}
                ],
                videos: []
            },
            {
                name: 'Xpresso Delight',
                hidden: false,
                projectid: 'xpresso-delight-website',
                description: ["### Website", "Xpresso Delight Cafe' quality coffee into workplaces all around New Zealand and Australia. I was given the design by Xpresso Delight's designer and from there.", "**Website developed with HTML and CSS to be responsive on all screen sizes and compatible with all browsers including Chrome, Firefox, Internet Explorer 9+, Safari & Opera**"],
                thumb: 'https://moehammoud.com/images/works/intro/xpresso_delight_website.png',
                images: [
                    {src: 'https://moehammoud.com/images/works/full/xpresso-delight-website.png', alt: 'Xpresso Delight'}
                ],
                videos: []
            },
            {
                name: 'Guccione Tennis',
                hidden: false,
                projectid: 'guccione-tennis-website',
                description: ["### Website", "Guccione Tennis club is a tennis and social club based in Melbourne","**Development of website utilising HTML, CSS and jQuery.**"],
                thumb: 'https://moehammoud.com/images/works/intro/guccione_tennis_website.png',
                images: [
                    {src: 'https://moehammoud.com/images/works/full/guccione_tennis_website.png', alt: 'Guccione Tennis'}
                ],
                videos: []
            },
            {
                name: 'Liquorland',
                hidden: false,
                projectid: 'liquorland-animated-html-display-banner',
                description: ["### Animated HTML Display Banner", "Built with **HTML, CSS and JavaScript for use with DoubleClick.** \n\n **Animated with Greensock.**"],
                thumb: 'https://moehammoud.com/images/works/exclusive/intro/liquorland-mrec-dispaly-banner.jpg',
                images: [
                    {src: 'https://moehammoud.com/images/works/exclusive/full/Coles/coles-liquor-mrec-banner.jpg', alt: ''}
                ],
                videos: []
            },
            {
                name: 'Officeworks',
                hidden: false,
                projectid: 'officeworks-back-to-uni-sprint-spend-smarter-sprint-game-banner',
                description: ["### Back to Uni Sprint Spend Smarter Sprint Game Banner", "Officeworks ran a campaign featuring a cool Spend Smarter Sprint game for their **Back To Uni** campaign.", "Built with **HTML, CSS and JavaScript for use with DoubleClick.** \n\n**Animated with Greensock.** "],
                thumb: 'https://moehammoud.com/images/works/exclusive/intro/officeworks-BTU-homepage-takeover-gameplay-sprint.jpg',
                images: [],
                videos: [
                    {'title': null,src: 'http://moehammoud.com/public/uploads/works/exclusive/full/officeworks-back-to-uni-spend-smarter-sprint.mp4'}
                ]
            },
            {
                name: 'Target',
                hidden: false,
                projectid: 'target-greatest-toy-sale-on-earth-digital-outdoor-banner',
                description: ["### Greatest Toy Sale on Earth \n \n ##### Digital Outdoor Banner","Utilising **After Effects, Expressions and native plugins**"],
                thumb: 'https://moehammoud.com/images/works/exclusive/intro/ToySale_Outdoor-intro.jpg',
                images: [],
                videos: [
                    {'title': null, src: 'http://moehammoud.com/public/uploads/works/exclusive/full/TAR0958_ToySale_Outdoor.mp4'}
                ]
            },
            {
                name: 'Holden Cascada',
                hidden: false,
                projectid: 'holden-cascada-let-life-in-tandem-html-banners',
                description: ["### Holden Cascada Let Life In \n\n ##### Tandem HTML Banners", "Built with **HTML, CSS and JavaScript.** \n\n**Animated with Greensock and Adobe Flash.** ","[Click Here to see real website demo (Non Responsive)](https://moehammoud.com/public/uploads/works/exclusive/full/Holden/Cascada-html-tandem-banner/demo/)"],
                thumb: 'https://moehammoud.com/images/works/exclusive/intro/Holden-Cascada-MREC-banner-thumb.jpg',
                images: [
                    {src: 'https://moehammoud.com/images/works/exclusive/full/Holden/holden-Cascada-MSN-full.jpg', alt: ''}
                ],
                videos: []
            },
            {
                name: 'Stone Fitness',
                hidden: false,
                projectid: 'stone-fitness-christmas-2014-video',
                description: ["### Christmas 2014 Video", "This video was shot and edited to showcase Stone Fitness' Boot Camp sessions held in the Melbourne Botanic Gardens in Richmond.Every Saturday Stone along with his posse of trainers hold a fitness regime for the general public. Every weekend without fail people arrive with bells on and put in their all to work out under their trainer's instruction.", "It was crucial that the right combination of toil, high energy and fun be captured and delivered through the final video. With a lot of running and great timing, we recorded some of the most intense and wonderfully infectious energy you could possibly get in winter on a Saturday morning. Thanks to Stone and all of his trainers and members who show up to exercise week in and week out, it's an inspiration!", "**Shot with Canon 70D. Edited with Adobe Premiere and After Effects.**"],
                thumb: 'https://moehammoud.com/images/works/intro/sf_bc_thumb_2.jpg',
                images: [],
                videos: [
                    {'title': null, src: 'https://www.youtube.com/embed/Twm1M64jyfA'}
                ]
            },
            {
                name: 'Ester Co. Short Film',
                hidden: false,
                projectid: 'ester-co-short-film',
                description: ["### Short Film", "Gary and David, two best friends and loyal Ester Co. employees, as they curiously ponder the question one fateful day, “What does Ester Co. do? As following answers ensues, employees at Ester Co. friends and questions are all put into question.", "**Directed by:** James Vinson", "**Director of Photography:** Charles Alexander", "**Editor / Visual Effects:** Darius Family", "**Music:** Gerard Mack", "**Sound Technician:** Mark Schuliga"],
                thumb: 'https://moehammoud.com/images/works/intro/Ester_co_film.png',
                images: [],
                videos: [
                    {'title': 'Ester Co. Trailer', src: 'https://player.vimeo.com/video/33003633'}
                ]
            },
            {
                name: 'Officeworks',
                hidden: false,
                projectid: 'officeworks-store-opening-lift-banners',
                description: ["### Store Opening Lift Banners", "Originally meant to be a simple fade in of the lockup developed into a more complex creative involving mask transformation.", "**Creative was built utilising After Effects.**"],
                thumb: 'https://moehammoud.com/images/works/exclusive/intro/officeworks-lift-screens-intro.jpg',
                images: [],
                videos: [
                    {'title': 'Version A', src: 'http://moehammoud.com/public/uploads/works/exclusive/full/Officeworks/OWL0285_LAM_ClarenceSt_Store_Opening_Lift_Screens_960x240_A.mp4'},
                    {'title': 'Version B', src: 'http://moehammoud.com/public/uploads/works/exclusive/full/Officeworks/OWL0285_LAM_ClarenceSt_Store_Opening_Lift_Screens_960x240_B.mp4'},
                    {'title': 'Version C', src: 'http://moehammoud.com/public/uploads/works/exclusive/full/Officeworks/OWL0285_LAM_ClarenceSt_Store_Opening_Lift_Screens_960x240_C.mp4'}
                ]
            },
            {
                name: 'Holden Captiva',
                hidden: false,
                projectid: 'holden-captiva-star-wars-edition-concourse-digital-outdoor-banner',
                description: ["### Star Wars Edition \n\n ##### Concourse Digital Outdoor Banner", "This was an interesting creative to build. The creative is displayed above an escalator for shoppers both while they're descending and ascending to view. Without sound and the Star Wars Crawl copyright I animated the text coming in to catch the eye initially before easing out to a stop and video reveal.", "**Creative built using Adobe After Effects and Photoshop.**"],
                thumb: 'https://moehammoud.com/images/works/exclusive/intro/Holden-captiva-star-wars-concourse-banner-intro.jpg',
                images: [],
                videos: [
                    {'title': null, src: 'http://moehammoud.com/public/uploads/works/exclusive/full/Holden/Holden-captiva-star-wars-concourse-banner.mp4'}
                ]
            }
        ]
    },
    hiddenWorks: [
        {
            name: "Doherty's Gym",
            hidden: true,
            projectid: 'dohertys-gym-womens-fitness-promo-video',
            description: ["### Women's Fitness Promo Video", "We worked with Stone Gye and the owner of Doherty's Gym City to produce a video that inspires women in Victoria to take the 90 Day Challenge at Stone Fitness' base camp: Doherty's Gym on Flinders Street.",
                "We got together at 4 am and the amazing people you see in the video, shot and editing a great display of fitness, strength and vitality. It was important to infuse the right combination of fun, hard work and inspiration that these women demonstrate on a regular basis, all with the the inclusive attitude that they possess. All within a short time frame. "],
            thumb: 'https://moehammoud.com/images/works/intro/dohertys-video-thumb.jpg',
            images: [],
            videos: []
        },
        {
            name: 'Sengul Fencing',
            hidden: false,
            projectid: 'sengul-fencing-business-cards',
            description: ["### Business Cards", "Worked with business owner to design and print business cards.", "Designed with Adobe Illustrator"],
            thumb: 'https://moehammoud.com/images/works/intro/sengul_fencing_business_cards.png',
            images: [
                {src: 'https://moehammoud.com/images/works/full/sengul-fencing-website.png', alt: 'Guccione Tennis'}
            ],
            videos: []
        }
    ],
    brands: [
        'https://moehammoud.com/images/works/brands/firstchoice.png',
        'https://moehammoud.com/images/works/brands/fortywinks.png',
        'https://moehammoud.com/images/works/brands/holden.png',
        'https://moehammoud.com/images/works/brands/lindt.png',
        'https://moehammoud.com/images/works/brands/liquorland.png',
        'https://moehammoud.com/images/works/brands/officeworks.png',
        'https://moehammoud.com/images/works/brands/rams.png',
        'https://moehammoud.com/images/works/brands/target.png'
        // 'https://moehammoud.com/images/works/brands/the-complete-dairy.png'
    ],
    contact: {}
}

export default pages;